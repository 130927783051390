<template>
    <div class="stu_Index">
        <div class="stu_image">
            <div class="stu_image_div" id="stu_w">
                <div class="back_stu">
                    <p class="back_stu_p">
                        <span>项目背景</span>
                    </p>
                    <p class="back_stu_text">
                        国际上，24+国家将编程纳入基础教育体系，东亚三发达国家分别在2012年、2017年、2018年开始在中小学普及编程教育，或将编程课程加入中小学考试，甚至作为必修课进行学习，欧洲19国也将编程纳入了教学体系并作为了青少年的必修课，北美强国也于2016宣布政府投资40亿美元开展编程教育，鼓励孩子学习编程。
                    </p>
                    <p class="back_stu_text">
                        随着智能技术的发展、教育理念的不断更新，作为综合了信息技术、电子工程、机械工程、控制理论、传感技术以及人工智能等前沿科技的机器人技术也在为教育改革贡献自己的力量。
                    </p>
                    <br>
                    <br>
                    <br>
                    <div class="back_s_button" @click="backBox()">
                        查看更多
                    </div>
                </div>
                <div class="_fankui_div" v-show="feedback">
                    <div class="_fankui_one">
                        <div class="back_fankui" @click="FKBox()">
                            <div class="back_fankui_two">
                                <img src="./../../../assets/img/text.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <img class="_image_a" src="../../../assets/img/gonglu.png" alt="">
                <img class="_image_b" src="../../../assets/img/gonglu1.png" alt="">
                <div class="image_one" v-preventReClick @click="Obox1()">
                    <img src="./../../../assets/img/img2.png" alt="">
                    <div class="image_one_div" :class="stepsList[0].state==1?'_back_two':stepsList[0].state==2?'_back_three':'_back_one'">
                        <span>① 目标用户</span>
                    </div>
                </div>
                <div class="image_two" v-preventReClick @click="Obox2()">
                    <img src="./../../../assets/img/img1.png" alt="">
                    <div class="image_two_div" :class="stepsList[1].state==1?'_back_two':stepsList[1].state==2?'_back_three':'_back_one'">
                        <span>② 目标客户</span>
                    </div>
                </div>
                <div class="image_three" v-preventReClick @click="Obox3()">
                    <img src="./../../../assets/img/img3.png" alt="">
                    <div class="image_three_div" :class="stepsList[2].state==1?'_back_two':stepsList[2].state==2?'_back_three':'_back_one'">
                        <span>③ 用户应用场景</span>
                    </div>
                </div>
                <div class="image_four" v-preventReClick @click="Obox4()">
                    <img src="./../../../assets/img/img4.png" alt="">
                    <div class="image_four_div" :class="stepsList[3].state==1?'_back_two':stepsList[3].state==2?'_back_three':'_back_one'">
                        <span>④ 客户购买场景</span>
                    </div>
                </div>
                <div class="image_five" v-preventReClick @click="Obox5()">
                    <img src="./../../../assets/img/img5.png" alt="">
                    <div class="image_five_div" :class="stepsList[4].state==1?'_back_two':stepsList[4].state==2?'_back_three':'_back_one'">
                        <span>⑤ 竞品分析</span>
                    </div>
                </div>
                <div class="image_six" v-preventReClick @click="Obox6()">
                    <img src="./../../../assets/img/img6.png" alt="">
                    <div class="image_six_div" :class="stepsList[5].state==1?'_back_two':stepsList[5].state==2?'_back_three':'_back_one'">
                        <span>⑥ 产品规划</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 加入课堂弹窗 -->
        <div class="sroom_Popup">
            <el-dialog
                title="加入课堂"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="450px"
                :before-close="handleClose"
            >
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" @submit.native.prevent>
                    <el-form-item label="课堂码：" prop="code">
                        <el-input v-model="ruleForm.code" placeholder="请输入课堂码"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" v-preventReClick @click="rolexit('ruleForm')">确定</el-button>
                </span>
                <el-dialog
                        width="450px"
                        title="选择小组"
                        custom-class="sroom_Popup"
                        :visible.sync="innerVisible"
                        :before-close="handleClose"
                        :show-close="false"
                        append-to-body>
                        <i class="el-icon-close closei" v-preventReClick @click="closeis('ruleForms')"></i>
                        <el-form ref="ruleForms" :model="ruleForms" :rules="rules" @submit.native.prevent>
                            <el-form-item label="选择小组：" prop="id">
                                <el-select v-model="ruleForms.id" placeholder="请选择要加入的组">
                                    <el-option
                                        v-for="(item,index) in teamList"
                                        :key="index"
                                        :label='item.name'
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                            <el-button size="small" v-preventReClick @click="closeis('ruleForms')">取 消</el-button>
                            <el-button size="small" type="primary" v-preventReClick @click="addteam('ruleForms','ruleForm')">确 定</el-button>
                        </span>
                    </el-dialog>
            </el-dialog>
        </div>
        <div class="back_Popup">
            <el-dialog
                :visible.sync="backVisible"
                :show-close="false"
                width="1110px"
                :before-close="handleClose"
            >
                <div class="back_Popup_div">
                    <i class="el-icon-close back_close" @click="backClose()"></i>
                    <div class="back_pop_title">
                        <span>项目背景</span>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            feedback:false,
            stepsList:[
                {state:0},{state:0},{state:0},{state:0},{state:0},{state:0}
            ],
            dialogVisible:false,
            innerVisible:false,
            backVisible:false,
            teamList:[],
            ruleForms:{
                id:''
            },
            ruleForm:{
                code:""
            },
            rules:{
                code:[
                    { required: true, message: '请输入六位课堂码'},
                    {pattern:/^\d+$/, message:'课堂码必须为数字值'}
                ],
                id:[
                    { required: true, message: '请选择小组', trigger:'change'}
                ]
            }
        }
    },
    methods: {
        getapp(){
            if(this.$store.state.cla_id != -1){
                this.axios.StuCourseinfo({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id
                }).then(res=>{
                    if(res.data.code==0){
                        this.$store.dispatch("team_id",res.data.data.users[0].tId)
                        var course = res.data.data.course
                        let date= new Date()
                        var y = date.getFullYear();
                        var m = date.getMonth() + 1;
                        m = m < 10 ? '0' + m : m;
                        var d = date.getDate();
                        d = d < 10 ? ('0' + d) : d;
                        var time  = y + '-' + m + '-' + d;
                        if(time == course.end && res.data.data.feedback==0 && course.state ==3){
                            this.feedback = true
                        }else{
                            this.feedback = false
                        }
                        this.stepsApp()
                    }else if(res.data.code==-3011){
                        this.$store.dispatch("cla_id",-1)
                        this.getapp()
                    }
                }).catch(err=>{

                })
            }else{
                this.dialogVisible = true
            }
        },
        stepsApp(){
            if(this.$store.state.cla_id!=-1){
                this.axios.StuCoursestep({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id
                }).then(res=>{
                    if(res.data.code==0){
                        this.stepsList = res.data.data.steps
                        if(res.data.data.id===99){
                            clearInterval(this.timer)
                            this.timer=null
                        }
                        // this.searchVals = res.data.data.id
                    }else if(res.data.code==-3011){
                        this.$store.dispatch("cla_id",-1)
                        // this.$message.error(res.data.message)
                        // this.form = []
                        this.stepsList = []
                        this.getapp()
                    }else{
                        this.stepsList = []
                    }
                }).catch(err=>{

                })
            }
        },
        //加入课堂
        rolexit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCoursecode({
                        token:this.$store.state.token,
                        code:this.ruleForm.code
                    }).then(res=>{
                        if(res.data.code==0){
                                this.cla_id = res.data.data.id
                                this.teamList = res.data.data.teams
                                this.innerVisible = true
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        // 选择小组
        addteam(formName,ruleForm){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.StuCoursejoin({
                        token:this.$store.state.token,
                        code:this.ruleForm.code,
                        id:this.ruleForms.id
                    }).then(res=>{
                        if(res.data.code==0){
                            this.innerVisible = false
                            this.dialogVisible= false
                            this.$refs[formName,ruleForm].resetFields();
                            this.$store.dispatch("cla_id",this.cla_id)
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        closeis(formName){
            this.innerVisible = false
            this.$refs[formName].resetFields();
        },
        backBox(){
            this.backVisible = true
        },
        backClose(){
            this.backVisible = false
        },
        // 目标用户
        Obox1(){
            if(this.stepsList[0].state==0){
                this.$message.error('此课程暂未开课')
            }else if(this.stepsList[0].state==2){
                this.$router.push({name:'UserAnalyAll'})
            }else{
                this.$router.push({name:'UserAnaly_A'})
            }
        },
        // 目标客户
        Obox2(){
            if(this.stepsList[1].state==0){
                this.$message.error('此环节暂未开启')
            }else if(this.stepsList[1].state==2){
                this.$router.push({name:'CustorAnalyAll'})
            }else{
                this.$router.push({name:'CustorAnaly_A'})
            }
        },
        // 目标用户应用场景分析
        Obox3(){
            if(this.stepsList[2].state==0){
                this.$message.error('此环节暂未开启')
            }else if(this.stepsList[2].state==2){
                this.$router.push({name:'TargetusersAll'})
            }else{
                this.$router.push({name:'Targetusers'})
            }
        },
        // 目标客户购买场景分析
        Obox4(){
            if(this.stepsList[3].state==0){
                this.$message.error('此环节暂未开启')
            }else if(this.stepsList[3].state==2){
                this.$router.push({name:'TargecustomerAll'})
            }else{
                this.$router.push({name:'Targecustomer'})
            }
        },
        // 竞品分析
        Obox5(){
            if(this.stepsList[4].state==0){
                this.$message.error('此环节暂未开启')
            }else if(this.stepsList[4].state==2){
                this.$router.push({name:'CompetingAll'})
            }else{
                this.$router.push({name:'Competing_A'})
            }
        },
        // 产品规划
        Obox6(){
            if(this.stepsList[5].state==0){
                this.$message.error('此环节暂未开启')
            }else if(this.stepsList[5].state==2){
                this.$router.push({name:'ProductplanAll'})
            }else{
                this.$router.push({name:'Productplan'})
            }
        },
        // 个人中心
        cenBox(){
            this.$router.push({name:'Personalcenter'})
            this.bankSwitch = false
        },
        //退出登录
        exits(){
            this.axios.userlogout({
                token:this.$store.state.token
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push('/login')
                    localStorage.clear()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        FKBox(){
            this.$router.push({name:'Userfeedback',query:{
                    cla_id:this.$store.state.cla_id
            }})
        },
        handleClose(done){

        }
    },
    beforeDestroy () {
            let that = this;
        clearInterval(that.timer);
            that.timer = null;
    },
    destroyed () {
        let that = this;
        clearInterval(that.timer);
        that.timer = null;
    },
    mounted() {
        this.getapp()
        this.timer = window.setInterval(() => {
            this.stepsApp()
        },  3000)
    }
};
</script>
<style scoped>
@import './../../../assets/css/student/A/StudentPage.css';
</style>
<style>
.fade-enter,.fade-leave-to{
    opacity: 0;
    transform: translateY(-15px);
}
.fade-enter-active,.fade-leave-active{
    transition: 0.3s cubic-bezier(0.25,0.8,0.5,1)!important;
}
.sroom_Popup .el-dialog__title{
    font-size: 16px;
    color: #fff;
}
.sroom_Popup .el-dialog__header{
    padding: 10px 20px;
}
.sroom_Popup.el-dialog{
    border-radius: 6px;
    background: rgba(25, 36, 105, 1);
    border: 1px solid #34FFFF;
}
.sroom_Popup .el-dialog{
    border-radius: 6px;
    background: rgba(25, 36, 105, 1);
    border: 1px solid #34FFFF;
}
.sroom_Popup .el-dialog__body{
    padding: 10px 40px;
}
.sroom_Popup .el-dialog__footer{
    text-align: center;
}
.sroom_Popup .el-button--small{
    width: 100px;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    padding: 0;
}
.sroom_Popup .el-select{
    width: 100%;
}
.sroom_Popup .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}
.back_Popup .el-dialog{
    background: transparent;
}
.back_Popup .el-dialog__header{
    padding: 0;
}
.back_Popup .el-dialog__body{
    padding: 0;
}
</style>